@media screen and (max-width: 500px) {
    .sliderText {
        font-size: 0.75rem !important;
        width: auto !important;
        line-height: 1.4 !important;
        margin-bottom: 10px !important;
    }
    .sliderBtn {
        font-size: 8px !important;
        padding: 7px 11px !important;
        text-align: center !important;
        height: auto !important;
    }
    .mySwiper {
        height: 200px !important;
    }
    .px-sm-3 {
        padding: 0 15px;
    }
}

/***** Global Slide *****/
.slide-right,
.slide-left {
    width: 100%;
}

/***** Slide Right *****/
.slide-right {
    animation: 3s slide-right;
}
@keyframes slide-right {
    from {
        margin-left: -100%;
    }
    to {
        margin-left: 0%;
    }
}

/***** Slide Left *****/
.slide-left {
    animation: 3s slide-left;
}

@keyframes slide-left {
    from {
        margin-left: 100%;
    }
    to {
        margin-left: 0%;
    }
}

.slide-down {
    animation: 1.5s slide-down;
}
@keyframes slide-down {
    from {
        margin-bottom: 100%;
    }
    to {
        margin-bottom: 0%;
    }
}

.slide-top {
    animation: 1.5s slide-top;
}
@keyframes slide-top {
    from {
        margin-top: 60%;
    }
    to {
        margin-top: 0%;
    }
}

.fade-in {
    animation: fadeIn ease 3s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.title-text {
    font-size: 15px;
    font-family: "Karla", arial !important;
}

/* .effectbtn {
    transition: all 0.5s;
    cursor: pointer;
    margin: 36px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
  }
  
  .effectbtn{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .effectbtn:after {
    content: '»';
    position: absolute;
    opacity: 0;  
    top: 14px;
    right: -20px;
    transition: 0.5s;
  }
  .effectbtn-e{
    text-decoration: none;
    
  }
  .effectbtn-e:hover{
    
  }
  .effectbtn:hover{
    padding-right: 24px;
    padding-left:8px;
    background-color: white !important;
  }
  
  .effectbtn:hover:after {
    opacity: 1;
    right: 10px;
    color: aliceblue !important;
  } */

/* .btn-anime {

    transition: all 0.5s;
    cursor: pointer;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
  }
  
  .btn-anime{
        background-color: #353995bf !important;

    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .btn-anime:after {
    content: '»';
    position: absolute;
    opacity: 0;  
    top: 14px;
    right: -20px;
    transition: 0.5s;
  }
  
  .btn-anime:hover{
    background-color: white !important;
    color: black;
    padding-right: 30px;
    padding-left:10px;
  }
  
  .btn-anime:hover:after {
    opacity: 1;
    right: 10px;
  }
   */

.btn-anime {
    transition: all 0.2s ease-in-out;
    position: relative;
}
.i {
    color: black !important;
}
.btn-anime .i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateX(20px) translateY(-50%);
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.btn-anime:hover {
    background-color: white !important;
    padding-right: 35px !important;
    transform: scale(1.05) !important;
}
.btn-anime:hover a {
    color: #000 !important;
}
.btn-anime a {
    color: #fff !important;
}

.btn-anime:hover .i {
    color: black !important;
    opacity: 1;
    transform: translateX(0) translateY(-50%);
    padding-right: 10px;
}

.swiper-slide a {
    display: inline-flex;
}

._home .swiper-pagination-bullet {
    background-color: #fff !important;
}

@media screen and (max-width: 768px) {
    .bg-overlay {
        background-color: rgb(0 0 0 / 19%);
    }
}
