.navbar-sticky{
    position: sticky !important;
    top: 0;
    bottom: auto;
    left: 0;
    z-index: 9999999;
    background: #fff;
    min-height: 80px !important;

}.logo {
    width: 170px;
    height: auto;
}.navbar-brand {
    height: auto !important;
}


.h-100px{height: 80px !important;}

.top_nav .top_nav_wrapper>ul>li.current_page_item{
    background: #f0f0f0;
    color: #333 !important;
}.top_nav .top_nav_wrapper>ul>li.current_page_item>a{color: #fff !important;}
ul#menu-main-menu > li {
    height: 100% !important;
    display: flex;
    align-items: center;
}li#menu-item-7446 {
    max-height: 40px !important;
    margin-left: 50px !important;

}

.top_nav .main_menu_nav>ul>li.current-menu-ancestor>a, .top_nav .main_menu_nav>ul>li.current-menu-parent>a, .top_nav .main_menu_nav>ul>li.current_page_ancestor>a, .top_nav .main_menu_nav>ul>li.current_page_item>a, .top_nav .main_menu_nav>ul>li.current_page_parent>a, .top_nav .main_menu_nav>ul>li:hover>a, .top_nav .top_nav_wrapper>ul>li.current-menu-ancestor>a, .top_nav .top_nav_wrapper>ul>li.current-menu-parent>a, .top_nav .top_nav_wrapper>ul>li.current_page_ancestor>a, .top_nav .top_nav_wrapper>ul>li.current_page_item>a, .top_nav .top_nav_wrapper>ul>li.current_page_parent>a, .top_nav .top_nav_wrapper>ul>li:hover{
    background: #f0f0f0 !important;
    color: #333 !important;
}.top_nav .main_menu_nav>ul>li>a, .top_nav .top_nav_wrapper>ul>li>a {
    color: #000;
}
i.fa {
    color: #275d91;
}
.header_socials {
    margin-left: 0 !important;
} 

.topbar-text{
    text-decoration: none !important;
    display: block;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0;
}

@media (min-width: 1400px){
.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px !important;
}
}

.top_nav .top_nav_wrapper>ul>li.btn-header:hover{
    background: #275d91 !important;
}
.collapse:not(.show) {
    display: none !important;
}
@media only screen and (max-width: 991px){
.mobile_header .top_nav_mobile .main_menu_nav>li>a {
    text-decoration: none;
    font-size: 16px;
    color: #f0f0f0 !important;
}}
.mobile_header .top_nav_mobile .main_menu_nav>li>a {
    text-transform: uppercase;
}

@media only screen and (max-width: 1024px){
.mobile_grid_landscape #menu_toggle button:before, .mobile_grid_landscape .menu_toggle button:after {
    content: '';
    position: absolute;
    left: 0;
    top: -8px;
    width: 26px;
    height: 4px;
    background: #002e5b;
    transform-origin: 1.5px center;
    transition: .3s;
    border-radius: 2px;
}
.mobile_grid_landscape #menu_toggle button:after, .mobile_grid_landscape .menu_toggle button:before 
{
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 26px;
    height: 4px;
    background: #002e5b;
    transform-origin: 1.5px center;
    transition: .3s;
    border-radius: 2px;
}
}

#menu_toggle, .menu_toggle{margin: 0 !important;} 