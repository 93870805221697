.text-footer{
    text-decoration-color: white;
    color: white !important;
    font-family: karla;
}
.two-cols-list{
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-flow: wrap;
}
.ttt:hover{
   color:#337ab7 !important;
}