.blogs-views-main > div {
    width: 100%;
    display: contents;
}
.text-blog{
    color: #002e5b !important;
    font-size: 16px;
    font-family: "karla";
    font-weight: 600;
}
.text-btn{
    color: #002e5b !important;
    font-size: 16px !important;
    font-family: "karla";
    font-weight: 700;
}